import React from 'react'
import { baseURL, profile } from '../../../utils/constants'
import { ProfilePicture } from '../../microcomponentes/images/ProfilePicture'

export const CommentsPost = ({ comments }) => {
    return (
        <div className='container' id='container-comment-post'>
            {comments.map((comment, index) => (
                <div key={index}>
                    <div className='container-comment-post-header'>
                        <ProfilePicture user={comment.id}/>
                        <h4>{comment.name} {comment.paternal_surename} {comment.maternal_surename}</h4>
                    </div>
                    <div className='container-comment-post-content'><div>{comment.comment}</div></div>
                </div>
            ))}
        </div>
    )
}