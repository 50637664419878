import React from 'react'

export const InputCredential = ({ value, onChange, placeholder, type , colspan=16}) => {
    return (
        <div className={`colspan-${colspan} input-form`}>
        <input
            type={type}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            />
        </div>

    );
}