import React from 'react';

export const ModalNew = ({ title, text, button1 = 'Aceptar', isVisible, onToggleVisibility, funcButton1 }) => {
    
    const handleButton1Click = () => {if (funcButton1) { funcButton1();}};

    return (
        <div className={`modal ${isVisible ? "modal-show" : ""}`} onClick={onToggleVisibility}>
            <div className='container' onClick={e => e.stopPropagation()}>
                <div>
                    <h2>{title}</h2>
                    <span>{text}</span>
                    <div className='buttons'>
                        <div className='button' onClick={handleButton1Click}>
                            <button>{button1}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
