import React, { useEffect, useState } from 'react';
import { getReactionImage } from '../../../utils/functions/';
import { ProfilePicture } from '../../microcomponentes/images/ProfilePicture';

export function handleDisplayUsersReactions(value) {
    const cardUsers = document.getElementById('card-users-megacontent');
    cardUsers.style.top = value ? '0%' : '';
}

export const CardUsersReactions = ({ reactions, users }) => {
    const [showUsers, setShowUsers] = useState([]);

    useEffect(() => {
        if (users && users.length > 0) {
            handleShowReactions('all');
        }
    }, [users]);
    
    function handleShowReactions(reaction) {
        const data = reaction === 'all' ? users : users.filter(item => item.reaction === reaction);
        setShowUsers(data);
    }

    return (
        <div id='card-users-megacontent'>
            <div id='card-users-reactions-invisible' onClick={() => handleDisplayUsersReactions(false)}></div>

            <div id='card-users-reactions'>
                <div>
                  <div className='card-users-reactions-header'>
                        <div key='0'>
                              <p onClick={() => handleShowReactions('all')}>Todos ({users.length})</p>
                        </div>

                        {reactions && reactions.map(reaction => (
                            <div key={reaction.id}>
                                <img src={getReactionImage(reaction.name)} onClick={() => handleShowReactions(reaction.id)}/>
                                <p>{reaction.count}</p>
                            </div>
                        ))}
                  </div>
                    
                  <div className='card-users-reactions-content'>
                        {showUsers && showUsers.map(user => (
                            <div key={user.id} className='card-users-reactions-user colspan-16'>
                                <ProfilePicture user={user.id}/>
                                <img src={getReactionImage(user.reaction_name)} className='reaction'/>
                                <p>{user.name} {user.paternal_surename} {user.maternal_surename}</p>
                            </div>
                        ))}
                  </div>
                </div>
            </div>
        </div>
    );
};