import React, { useEffect, useRef, useState } from 'react';
import { createCookie, getAllReactions, reactionPost, sendReaction } from '../../../utils/functions';
import { getReactionImage } from '../../../utils/functions/';
import { getImageProfile } from '../../../utils/functions/imageProfile';
import { baseURL, comment, imageExtensions, profile, video, videoExtensions } from '../../../utils/constants';
import { ProfilePicture } from '../images/ProfilePicture';
import { useNavigate } from 'react-router-dom';

export const Post = ({ postId, name, date, text, media, userId, userPostId, notification=false, comments }) => {
    const [reactions, setReactions] = useState([]);
    const [reactioneds, setReactioneds] = useState([]);
    const [displayReactions, setDisplayReactions] = useState(false);
    const [reactionChecked, setReactionChecked] = useState(0);
    const [totalReactions, setTotalReactions] = useState(null);
    const [imageProfile, setImageProfile] = useState([]);

    const navigate = useNavigate();


    //Get route extension *********************************************
    const getFileExtension = (filename) => {
        if(filename){
            return filename.split('.').pop().toLowerCase(); 
        } 
    };

    const fileExtension = getFileExtension(media);

    const isImage = imageExtensions.some(ext => ext === fileExtension);
    const isVideo = videoExtensions.some(ext => ext === fileExtension);
    //*****************************************************************

      
    useEffect(() => {
        const fetchAllReactions = async () => {
          const response = await getAllReactions();
          setReactions(response.data.data);
          fetchGetImageProfile();
        };

        async function fetchGetImageProfile() {
            const image = await getImageProfile(userPostId);
            setImageProfile(image)

        }

        const fetchReactionPost = async (post, user) => {
            const response = await reactionPost(post, user);
            setReactioneds(response.data.data);
            setReactionChecked(response.data.reactioned.name);

            let counter = null;
            if(response.data.data != undefined){
                response.data.data.forEach(item => {
                   counter += item.count;
                });
            }
            setTotalReactions(counter);
          };
    
        fetchAllReactions();
        fetchReactionPost(postId, userId);
    }, []);

    async function handleSendReaction(reaction, user, post, reactions){
        const { updatedReactions, reactioneds, reactionChecked, counter } = await sendReaction(reaction, user, post, reactions);
        setDisplayReactions(false);
        setReactions(updatedReactions);
        setReactioneds(reactioneds);
        setReactionChecked(reactionChecked);
        setTotalReactions(counter);
    }

    const handleShowDisplay = () => {
        setDisplayReactions(!displayReactions);
    };

    //////////////////////////////////////////////////////////////////////////////
    document.addEventListener("DOMContentLoaded", function() {
        var videos = document.querySelectorAll('video[autoplay]');
    
        var observer = new IntersectionObserver((entries, observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting){
                    entry.target.play();
                } else{
                    entry.target.pause();
                }
            });
        }, { threshold: 1 });
    
        videos.forEach(video => { observer.observe(video); });
    });

    function openPost(post, write=false){
        createCookie({
          name: 'post_opened',
          data: post
        })

        createCookie({
          name: 'write',
          data: write
        })
        navigate('/post')
    }

    return (
        <div key={postId} className='post'>
            <div>

                <div className='post-header'>
                    <ProfilePicture user={userPostId}/>

                    <div className='post-data'  onClick={notification ? () => openPost(postId) : undefined}>
                        <div><h5>{name}</h5></div>
                        <div><p>{date.split('T')[0]}</p></div>
                    </div>
                </div>

                <div className='post-content'>
                    <div className='post-text'   onClick={notification ? () => openPost(postId) : undefined}>{text} {notification && !text ? `${name} ha enviado un archivo` : ''}</div>
                    {media && isImage && <div className='post-media'><img src={baseURL + media} onClick={() => openPost(postId)} /></div>}
                    {media && isVideo && ( <div className='post-media'> <video autoPlay muted controls playsInline onClick={() => openPost(postId)}> <source src={baseURL + media} type={`video/${fileExtension}`} /> </video></div>)}
                    {media && !isImage && !isVideo  && <div className='post-media' onClick={() => openPost(postId)}><div></div><iframe src={media} frameborder="0" scrolling='no'></iframe></div>}
                </div>

                {
                    !notification ?

                    <div className='post-footer'>
                        <div className='post-reactioned'>
                          {reactioneds && reactioneds.map((reaction) => (
                            <div key={reactioneds.id} onClick={() => openPost(postId)}>{reaction.user}<img src={getReactionImage(reaction.name)}/></div>
                          ))}
                            <div className='post-reactioned-count'><p>{totalReactions}</p></div>

                            <div className='post-comments' onClick={() => openPost(postId)}><p>{comments} Comentarios</p></div>
                        </div>

                        <div className='post-reacts'>
                            {displayReactions && reactions.map((react, index) => (
                                <div onClick={async () => await handleSendReaction(react.id, userId, postId, reactions)} className='post-react' key={index}><img src={getReactionImage(react.name)} alt={react.name} /></div>
                            ))}
                        </div>

                        <div className='post-actions'>
                            <div className={`post-button-react checked ${reactionChecked}`} onClick={() => handleShowDisplay()}><img src={getReactionImage(reactionChecked)} /></div>
                            <div className='post-comment' onClick={() => openPost(postId, true)}><img src={comment}/><p>Comentar</p></div>
                        </div>
                </div>:''
                }

            </div>

        </div>
    );
};