import React from 'react'
import { useNavigate } from 'react-router-dom';

export const TextNavigate = ({text='text', link='link', navigate='/', colspan=16}) => {
    const textNavigate = useNavigate();

    function textNavigation(nav){
        textNavigate(nav)
    }

  return (
    <div className={`colspan-${colspan} text-navigate`}>
        <p>{text} <span onClick={() => textNavigation(navigate)}>{link}</span></p>
    </div>
  )
}