import React from 'react'
import { PageError } from '../components/PageError'
import { error404 } from '../../utils/constants/assets'

export const Error404 = () => {
  return (
    <div>
        <PageError image={error404} error={'404'}/>
    </div>
  )
}
