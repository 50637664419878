import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getCookie } from "./cookies";
import axios from "axios";
import { validateToken } from "../constants/api";

export const Credentials = (roleID) => {
    const navigate = useNavigate();
    const [userRole, setUserRole] = useState(null);

    useEffect(() => {
        const accessToken = getCookie('accessToken');

        const getDataUser = () => {
            axios.get(validateToken + accessToken)
                .then(response => {
                    if (response && response.data && response.data.data && response.data.data.id_status) {
                        if (response.data.data.id_role < roleID) {
                            navigate('/404');
                        }
                        if (response.data.data.id_status !== 1) {
                            navigate('/404');
                        }
                        if (response.data.data.id_status === 1) {
                            setUserRole(response.data.data.id_role);
                        }
                    } else {
                        navigate('/login');
                    }
                })
                .catch(error => navigate('/login'));
        };

        getDataUser();
    }, [navigate]);

    return userRole;
};

export const CredentialsId = () => {
    const navigate = useNavigate();
    const [data, setIdData] = useState(null);

    useEffect(() => {
        const fetchDataUser = async () => {
            const accessToken = getCookie('accessToken');
            const response = await axios.get(validateToken + accessToken)
               .then(response => {
                    if (response.data.data) { setIdData(response.data.data.id);}
                    else{navigate('/login');}
                })
                .catch(error => navigate('/404'));
        };

        fetchDataUser();
    }, []);
    return data;
};

export const AutoLogin = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const accessToken = getCookie('accessToken');

        const getDataUser = () => {
            axios.get(validateToken + accessToken)
                .then(response => {
                    if (response && response.data && response.data.data && response.data.data.id_status) {
                        navigate('/')
                    }
                })
                .catch(error => navigate('/login'));
        };

        getDataUser();
    }, [navigate]);
};