import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';

export const IconNavbar = ({link, image, text, func, counter=0, callFunction}) => {
    const iconNavbarNavigate = useNavigate();
    const location = useLocation();
    const [ isActive, setIsActive ] = useState(false);

    function iconNavbarNavigation(nav) {
      if(callFunction){
        callFunction();
      }

      iconNavbarNavigate(nav);
  
      window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth'
      });
    }

    useEffect(() => {
      if(location.pathname == link){
        setIsActive(true);
      }else{
        setIsActive(false);
      }
  }, [location.pathname]);

  return (
    <div onClick={() => iconNavbarNavigation(link)} className={isActive ? 'isActive' : ''}>
        <img src={image}/>
        {counter > 0 ? <div className='counter'>{counter}</div> : ''}
    </div>
  )
}