import React from 'react'
import { FormRegister } from '../components/forms/FormRegister'
import { AutoLogin } from '../../utils/functions'

export const Register = () => {
    AutoLogin();

    return (
        <div>
            <FormRegister/>
        </div>
    )
}
