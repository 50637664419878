import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { CredentialsId, createCookie } from '../../../utils/functions';
import { apiGetPosts, baseURL } from '../../../utils/constants';
import { useNavigate } from 'react-router-dom';
import { Post } from '../../microcomponentes/posts/Post';

export const Posts = () => {
    const navigate = useNavigate();
    const UserId = CredentialsId();

    const [posts, setPosts] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState([]);

    const getPosts = (currentPage) => {
        const page = `?page=${currentPage}`;
        axios.get(`${apiGetPosts}${page}`)
            .then(response => {
                setPosts(prevPosts => [...prevPosts, ...response.data.data.data]);
                setTotalPages(response.data.data.last_page);
            })
            .catch(error => console.error(navigate('/404')))
    };

    const handleScroll = () => {
        if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
          if(currentPage < totalPages){setCurrentPage((prevPage) => prevPage + 1);}
        }
      };

      useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };

      });

      useEffect(() => {
        getPosts(currentPage)
      }, [currentPage]);



    return(
        <div className='container' id='container-posts'>
            {posts.map((post) => (            
                <Post
                postId={post.id}
                userPostId={post.id_user}
                name={`${post.name} ${post.paternal_surename} ${post.maternal_surename}`}
                text={post.text}
                date={post.created_at}  
                userId={UserId}
                media={ post.file ? post.file : null}
                comments={ post.comments}
                />
            ))
            }
        </div>
    )
}