import axios from "axios";
import { apiGetUsers } from "../constants";

export const getUsers = async (role) => {
    const response = await axios.get(apiGetUsers, {
        params: {
            id_role: role
        }
    });

    return response;
}