import React, { useState, useRef, useEffect } from 'react';
import { CredentialsId, fetchPost, handleClick } from '../../../utils/functions';
import { apiSendPost, clip, notfyAll, send } from '../../../utils/constants';

export const CreatePost = ({notification = 0}) => {
  const userId = CredentialsId();
  const fileInputRef = useRef(null);

  const [text, setText] = useState('');
  const [file, setFile] = useState(null);


  const handleText = (e) => {setText(e.target.value);};

  const handleFile = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
  };

  const handleSubmit = (e) => {

    e.preventDefault();
    postData();
  
    async function postData() {
      const formData = new FormData();
  
      const urlRegex = /(https?:\/\/[^\s]+)/g;
      const urls = text.match(urlRegex);
  
      let videoUrl = null;
      if (urls) {
        videoUrl =urls[0];
        // Filtrar y encontrar la URL específica de YouTube
        //videoUrl = urls.find(url => url.includes("youtube.com") || url.includes("youtu.be"));
        formData.append('file', videoUrl);
      }else{
        formData.append('file', file);
      }
  
      formData.append('text', text);
      formData.append('id_user', userId);
      formData.append('notification', notification);

      const { data } = await fetchPost({
        url: apiSendPost,
        params: formData,
        headers: {'Content-Type': 'multipart/form-data'},
      });

      window.location.reload();
    }
  };
  

  return (
    <div className='container' id='container-create-post'>
      <div>
        <div className='create-post-input'>
          <textarea type='text' value={text} onChange={handleText} placeholder={'¿En qué estás pensando?'}/>
        </div>
        <div className='create-post-tools'>
          <input type='file' id='file'  onChange={handleFile} style={{ display: 'none' }} />
          <img src={clip} onClick={() => handleClick('file')} />
          <img src={send} onClick={handleSubmit}/>
        </div>
      </div>
    </div>
  );
};