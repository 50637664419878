import React from 'react'
import { Navbar } from '../components/navbars/Navbar'
import { NavbarHeader } from '../components/navbars/NavbarHeader'
import { SettingsContainer } from '../components/containers/SettingsContainer'

export const Settings = () => {
  return (
    <div>
        <Navbar/>
        <NavbarHeader/>
        <SettingsContainer/>
    </div>
  )
}
