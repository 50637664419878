export const baseURL = 'https://apiring.stullerlandcommunity.com/public/';
//export const baseURL = 'http://127.0.0.1:8000/';
export const folderAPI = 'api/';

//users
export const apiRegister = `${baseURL}${folderAPI}register`;
export const apiLogin = `${baseURL}${folderAPI}login`;
export const apiGetUsers = `${baseURL}${folderAPI}users`;
export const apiChangeUserStatus = `${baseURL}${folderAPI}status/`;
export const validateToken = `${baseURL}${folderAPI}validateToken/`;
export const getProfile = `${baseURL}${folderAPI}profile/`;
export const updateCover = `${baseURL}${folderAPI}cover/`;

//posts
export const apiSendPost = `${baseURL}${folderAPI}post`;
export const apiGetPosts = `${baseURL}${folderAPI}posts`;
export const apiGetPost = `${baseURL}${folderAPI}post/`;
export const apiCommentPost = `${baseURL}${folderAPI}commentPost`;
export const apigetCommentsPost = `${baseURL}${folderAPI}commentsPost/`;

//reactions
export const apiGetReactions = `${baseURL}${folderAPI}reactionsPost/`;
export const apiSendReaction = `${baseURL}${folderAPI}sendReactionPost`;
export const apiReactions = `${baseURL}${folderAPI}reactions`;

//profile picture
export const apiLoadProfilePicture = `${baseURL}${folderAPI}ProfilePictureLoad`;
export const apiGetProfilePicture = `${baseURL}${folderAPI}ProfilePictureGet/`;

//notifications
export const notifications = `${baseURL}${folderAPI}notifys/`;
export const notfyAll = `${baseURL}${folderAPI}notify`;
export const notificationOpened = `${baseURL}${folderAPI}opened/`;
export const countNotifications = `${baseURL}${folderAPI}count/`;