import React from 'react'
import { Route, Routes, useLocation } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Dashboard } from './components/views/Dashboard';
import { Login } from './components/views/Login';
import { Register } from './components/views/Register';
import { Profile } from './components/views/Profile';
import { Settings } from './components/views/Settings';
import { Notifications } from './components/views/Notifications';
import { PostOpened } from './components/components/containers/PostOpened';
import { Error404 } from './components/views/Error404';
import { Users } from './components/views/Users';

export const Transitions = () => {
    const location = useLocation();
    return (
      <TransitionGroup component={null}>
        <CSSTransition key={location.key} classNames="fade" timeout={300}>
          <Routes location={location}>

          <Route path='/' element={ <Dashboard/> } />
            <Route path='/login' element={ <Login/> } />
            <Route path='/register' element={ <Register/> } />

            <Route path='/users' element={ <Users/> } />
            <Route path='/profile' element={ <Profile/> } />
            <Route path='/settings' element={ <Settings/> } />
            <Route path='/notifications' element={ <Notifications/> } />

            <Route path='/post' element={ <PostOpened/> } />
            <Route path='/404' element={ <Error404/> } />
            <Route path='*' element={<Error404 />} />

          </Routes>
        </CSSTransition>
      </TransitionGroup>
    );
}