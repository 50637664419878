import Cookies from 'js-cookie'

export function createCookie({name='Cookie', data=null, time=5}){
    const expiryDate = new Date();
    expiryDate.setFullYear(expiryDate.getFullYear() + time);

    Cookies.set(name, data, {
        expires: expiryDate,
        sameSite: 'strict'
    });
}

export function getCookie(cookieName){
    const cookie = Cookies.get(cookieName);
    return cookie;
}

export function deleteCookie(name){
    Cookies.remove(name);
}