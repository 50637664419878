import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/styles.css'
import { HashRouter, Routes, Route } from 'react-router-dom';
import { Dashboard } from './components/views/Dashboard';
import { Login } from './components/views/Login';
import { Users } from './components/views/Users';
import { Settings } from './components/views/Settings';
import { Register } from './components/views/Register';
import { Error404 } from './components/views/Error404';
import { PostOpened } from './components/components/containers/PostOpened';
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { Notifications } from './components/views/Notifications';
import { Profile } from './components/views/Profile';
import { Transitions } from './Transitions';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <HashRouter>
    <Transitions/>
  </HashRouter>
);

serviceWorkerRegistration.register();