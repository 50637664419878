import { useState } from "react";

export const useCredentialsUser = () => {
    const [name, setName] = useState('');
    const handleName = (value) => setName(value);
  
    const [paternalSurename, setPaternalSurename] = useState('');
    const handlePaternalSurename = (value) => setPaternalSurename(value);
  
    const [maternalSurename, setMaternalSurename] = useState('');
    const handleMaternalSurename = (value) => setMaternalSurename(value);
  
    const [curp, setCurp] = useState('');
    const handleCurp = (value) => setCurp(value);
  
    const [employNumber, setEmployNumber] = useState('');
    const handleEmployNumber = (value) => setEmployNumber(value);
  
    const [password, setPassword] = useState('');
    const handlePassword = (value) => setPassword(value);
  
    const [confirmPassword, setConfirmPassword] = useState('');
    const handleConfirmPassword = (value) => setConfirmPassword(value);
  
    const [birth, setBirth] = useState('');
    const handleBirth = (value) => setBirth(value);
  
    const [idGender, setIdGender] = useState('');
    const handleIdGender = (value) => setIdGender(value);

    const [legend, setLegend] = useState('');
    const handleLegend = (value) => setLegend(value);
  
    return{
      name,
      handleName,
      paternalSurename,
      handlePaternalSurename,
      maternalSurename,
      handleMaternalSurename,
      curp,
      handleCurp,
      employNumber,
      handleEmployNumber,
      password,
      handlePassword,
      confirmPassword,
      handleConfirmPassword,
      birth,
      handleBirth,
      idGender,
      handleIdGender,
      legend,
      handleLegend
    }
}