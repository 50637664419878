import React, { useEffect, useState } from 'react';
import { Credentials, CredentialsId, createCookie } from './../../../utils/functions/';
import { home, notification, profile, search, settings } from '../../../utils/constants/assets';
import { IconNavbar } from '../../microcomponentes/images/IconNavbar';
import axios from 'axios';
import { countNotifications } from '../../../utils/constants';

export const Navbar = () => {
    const role = Credentials();
    const userId = CredentialsId();

    const [ notifications, setNotifications ] = useState(0);

    const handleScroll = () => {
        const navbar = document.querySelector('#navbar');
        let lastScrollTop = 0;

        window.addEventListener('scroll', () => {
            const currentScroll = window.pageYOffset || document.documentElement.scrollTop;

            if (currentScroll > lastScrollTop) {
                navbar.style.bottom = '-100px';
            } else {
                navbar.style.bottom = '';
            }

            lastScrollTop = currentScroll <= 0 ? 0 : currentScroll;
        });
    };

    async function getCountNotifications() {
        const response = await axios.get(countNotifications + userId);
        setNotifications(response.data.data);
    }

    function profileFunc(){
        createCookie({
            name: 'profile',
            data: userId
        })
    }



    useEffect(() => {
        getCountNotifications();
    });

    React.useEffect(() => {
        handleScroll();
    }, []);

    return (
        <div className='navbar' id='navbar'>
            <div>
                {role >= 1 && (
                    <>
                        <IconNavbar link={'/'} image={home} text={'Inicio'}/>
                
                        {role >= 2 && (
                            <>
                                <IconNavbar link={'/users'} image={search} text={'Users'}/>
                            </>
                        )}
                        
                        <IconNavbar link={'/notifications'} image={notification} text={'Notificar'} counter={notifications}/>
                        <IconNavbar link={'/profile'} image={profile} text={'Perfil'}  callFunction={profileFunc}/>
                        <IconNavbar link={'/settings'} image={settings} text={'Ajustes'}/>
                    </>
                )}
            </div>
        </div>
    );
};