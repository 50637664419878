import React, { useEffect, useState } from 'react'
import { CredentialsId } from '../../../utils/functions';
import { notifications } from '../../../utils/constants';
import axios from 'axios';
import { Post } from '../../microcomponentes/posts/Post';

export const NotificationsList = () => {
  const userId = CredentialsId();
  const [ nofitications, setNotifications ] = useState([]);

  async function getNotifications() {
    const response = await axios.get(notifications + userId);
    setNotifications(response.data.data);
  }

  useEffect(() => {
    getNotifications()
  }, [userId]);

  return (
    <div className='container' id='container-posts'>
      {nofitications.map((notification) => (            
                <Post
                postId={notification.id}
                userPostId={notification.id_user}
                name={`${notification.name} ${notification.paternal_surename} ${notification.maternal_surename}`}
                text={notification.text}
                date={notification.created_at}  
                userId={userId}
                media={ null}
                notification={true}
                />
      ))}
    </div>
  )
}
