import React, { useState, useRef } from 'react';
import { CredentialsId, fetchPost } from '../../../utils/functions';
import { send, notfyAll } from '../../../utils/constants';

export const CreateNotification = () => {
  const userId = CredentialsId();
  const fileInputRef = useRef(null);

  const [text, setText] = useState('');

  const handleText = (e) => {setText(e.target.value);};

  const handleSubmit = (e) => {
    e.preventDefault();
    postData();
  
    async function postData() {
      const formData = new FormData();
      formData.append('text', text);
      formData.append('by', userId);
  
      const { data } = await fetchPost({
        url: notfyAll,
        params: formData,
      });

      setText('');
    }
  };
  

  return (
    <div className='container' id='container-create-post'>
      <div>
        <div className='create-post-input'>
          <textarea type='text' value={text} onChange={handleText} placeholder={'Redacta y envía notificaciones'}/>
        </div>
        <div className='create-post-tools'>
          <img src={send} onClick={handleSubmit}/>
        </div>
      </div>
    </div>
  );
};