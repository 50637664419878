import React, { useEffect, useState } from 'react'
import { apiChangeUserStatus } from '../../../utils/constants';
import axios from 'axios';
import { Credentials } from '../../../utils/functions';
import { getUsers } from '../../../utils/functions/UserStatus';
export const ListUsersStatus = () => {
    const role = Credentials();

    const [users, setUsers] = useState([]);

    useEffect(() => {
        fetchDataGetUsers();
    }, [role]);

    const fetchDataGetUsers = async () => {
        if (role !== null) {
            const response = await getUsers(role);
            setUsers(response.data.data.data);
        }
    };

    const changeStatus = async (user, status) => {
        await axios.put(apiChangeUserStatus + user, {
            id_status: status
        });
        fetchDataGetUsers();
    }

    return (
        <div className='container' id='container-list-users-status'>
            <div>
                
                <div className='colspan-16'>
                  <div className='colspan-6'>NOMBRE</div>
                  <div className='colspan-4'>EMPLEADO</div>
                  <div className='colspan-6'>CURP</div>
                </div>

                {users.length > 0 ? (
                  users.map((user) => (
                    <div className='colspan-16' key={user.id}>
                      <div className='colspan-6'>{user.name} {user.paternal_surename} {user.maternal_surename}</div>
                      <div className='colspan-4'>{user.employ_number}</div>
                      <div className='colspan-6'>{user.curp}</div>

                    {user.id_status === 8 && (
                      <div className='colspan-16 button'>
                        <button onClick={() => changeStatus(user.id, 1)}>Validar</button>
                      </div>
                    )}

                    {user.id_status === 1 && (
                      <div className='colspan-16 button'>
                        <button onClick={() => changeStatus(user.id, 8)}>Revocar</button>
                      </div>
                    )}

                </div>
                ))
                ) : (
                <p>No hay usuarios disponibles</p>
                )}
            </div>
        </div>
      );
      
}
