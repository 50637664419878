import React from 'react'
import { logoApp } from '../../../utils/constants'

export const NavbarHeader = () => {

    const handleScroll = () => {
        const navbar = document.querySelector('#navbar-header');
        let lastScrollTop = 0;

        window.addEventListener('scroll', () => {
            const currentScroll = window.pageYOffset || document.documentElement.scrollTop;

            if (currentScroll > lastScrollTop) {
                navbar.style.top = '-100px';
            } else {
                navbar.style.top = '';
            }

            lastScrollTop = currentScroll <= 0 ? 0 : currentScroll;
        });
    };

    React.useEffect(() => {
        handleScroll();
    }, []);

    return (
        <div className='navbar' id='navbar-header'>
            <div>
                <img src={logoApp}/>
                <p>Rings</p>
            </div>
        </div>
    )
}
