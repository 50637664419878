import React from 'react'
import { Navbar } from '../components/navbars/Navbar'
import { NavbarHeader } from '../components/navbars/NavbarHeader'
import { CreateNotification } from '../components/containers/CreateNotification'
import { Credentials } from '../../utils/functions'
import { NotificationsList } from '../components/containers/NotificationsList'
import { CreatePost } from '../components/containers/CreatePost'

export const Notifications = () => {
  const userRole = Credentials(1);

  return (
    <div>
        <Navbar/>
        <NavbarHeader/>

        {userRole >= 2 && (<><CreatePost notification={1}/></>)}
        <NotificationsList/>
    </div>
  )
}
