import axios from "axios";
import { angry, apiGetReactions, apiReactions, apiSendReaction, haha, like, love, sad, wow } from "../constants";
import { fetchPost } from "./useFetech";

export const getReactionImage = (reaction) => {
    return reactionsImages[reaction] || like;
};

export const reactionsImages = {
    angry: angry,
    haha: haha,
    like: like,
    love: love,
    sad: sad,
    wow: wow
};

export const getAllReactions = () => {
    return axios.get(apiReactions)
      .then(response => {return response;})
      .catch(error => {
        console.error(error);
        return null;
      });
};

export const reactionPost = (post, user) => {
    return axios.get(apiGetReactions + post, {
      params: {
        user_id: user
      }
    })
      .then(response => {return response;})
      .catch(error => {
        console.error(error);
        return null;
      });
};

export  const updateReactions = (reactions, reaction) => {
    return reactions.map((react) => {
      if (react.id === reaction) {
        return { ...react, count: react.count + 1 };
      }
      return react;
    });
};

export async function sendReaction(reaction, user, post, reactions) {
    await fetchPost({
        url: apiSendReaction,
        params: {
            "id_reaction": reaction,
            "id_user": user,
            "id_post": post
        }
    });

    const reactionsPost = await axios.get(apiGetReactions + post, {
        params: {
          user_id: user
        }
    });

    const updatedReactions = updateReactions(reactions, reaction);
    const reactioneds =  reactionsPost.data.data;
    const reactionChecked = reactionsPost.data.reactioned.name;

    let counter = null;
    if(reactionsPost.data.data != undefined){
        reactionsPost.data.data.forEach(item => {
          counter += item.count;
      });
    }

    return{updatedReactions, reactioneds, reactionChecked, counter}
}