import React from 'react'
import { ListUsersStatus } from '../components/containers/ListUsersStatus'
import { Navbar } from '../components/navbars/Navbar'
import { NavbarHeader } from '../components/navbars/NavbarHeader'

export const Users = () => {
  return (
    <div>
        <Navbar/>
        <NavbarHeader/>
        
        <ListUsersStatus/>
    </div>
  )
}
