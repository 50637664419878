import React from 'react'
import { useNavigate } from 'react-router-dom'

export const PageError = ({image, error}) => {
  const navigate = useNavigate();

  return (
    <div className='page-error'>
        <img src={image}/>
        <p>{error}</p>
        <button onClick={() => navigate('/')}>Regresar</button>
    </div>
  )
}
