import React, { useState } from 'react'
import { useCredentialsUser } from '../../../utils/hooks/useCredentialsUser';
import { fetchPost } from '../../../utils/functions';
import { Legend } from '../../microcomponentes/texts/Legend';
import { InputCredential } from '../../microcomponentes/inputs/InputCredential';
import { TitleForm } from '../../microcomponentes/titles/TitleForm';
import { TextNavigate } from '../../microcomponentes/texts/TextNavigate';
import { apiRegister } from '../../../utils/constants';
import { SubmitForm } from '../../microcomponentes/buttons/SubmitForm';
import { logoApp } from '../../../utils/constants/assets';
import { LogoForm } from '../../microcomponentes/images/LogoForm';
import { ModalNew } from '../modals/ModalNew';
import { useNavigate } from 'react-router-dom';

export const FormRegister = () => {
    const { name, handleName } = useCredentialsUser();
    const { paternalSurename, handlePaternalSurename } = useCredentialsUser();
    const { maternalSurename, handleMaternalSurename } = useCredentialsUser();
    const { curp, handleCurp } = useCredentialsUser();
    const { employNumber, handleEmployNumber } = useCredentialsUser();
    const { password, handlePassword } = useCredentialsUser();
    const { confirmPassword, handleConfirmPassword } = useCredentialsUser();
    const { legend, handleLegend } = useCredentialsUser();
    const navigate = useNavigate();

    const handleFormSubmit = (e) => {
        e.preventDefault();
        postData()

        async function postData(){
            const { data } = await fetchPost({
                url: apiRegister,
                //params: {
                //    "name": name,
                //    "paternalSurename": paternalSurename,
                //    "maternalSurename": maternalSurename,
                //    "curp": curp,
                //    "employNumber": employNumber,
                //    "password": password,
                //    "confirmPassword": confirmPassword
                //}
                params: {
                  "name": name,
                  "paternalSurename": '.',
                  "maternalSurename": '.',
                  "curp": employNumber + password,
                  "employNumber": employNumber,
                  "password": password,
                  "confirmPassword": confirmPassword
              }
            })
            handleLegend(data.message);

            
            if(data.code == 201){
              handleName('');
              handlePaternalSurename('');
              handleMaternalSurename('');
              handleCurp('');
              handleEmployNumber('');
              handlePassword('');
              handleConfirmPassword('');
              accotunCreated();
            }
        }
    }

    function goLogin(){
      navigate('/login');
    }

    const [isModalVisible, setIsModalVisible] = useState(false);
    const accotunCreated = () => {setIsModalVisible(!isModalVisible);};
    
    return (
    <>
    <div className='card' id='card-register'>
      <form>
        <TitleForm  colspan={15} title='Register'/>
        <LogoForm colspan={1} image={logoApp}/>
        <InputCredential type='text' placeholder='Nombre' value={name} onChange={(e => handleName(e.target.value))}/>

        <InputCredential type='text' placeholder='Número de empleado' value={employNumber} onChange={(e => handleEmployNumber(e.target.value))}/>
        <InputCredential type='password' placeholder='Contraseña' value={password} onChange={(e => handlePassword(e.target.value))}/>
        <InputCredential type='password' placeholder='Confimar contraseña' value={confirmPassword} onChange={(e => handleConfirmPassword(e.target.value))}/>
        <Legend text={legend}/>
        <SubmitForm onClick={handleFormSubmit}/>
        <TextNavigate text='¿Ya tienes cuenta?' link='inicia sesión aquí' navigate='/login'/>
      </form>
    </div>

    <ModalNew
      funcButton1={goLogin}
      onToggleVisibility={accotunCreated}
      isVisible={isModalVisible}
      title={'Registro exitoso'}
      button1='Iniciar sesión'
    />
    </>
    )
}

//<InputCredential type='text' placeholder='Apellido Paterno' value={paternalSurename} onChange={(e => handlePaternalSurename(e.target.value))}/>
//<InputCredential type='text' placeholder='Apellido Materno' value={maternalSurename} onChange={(e => handleMaternalSurename(e.target.value))}/>
//<InputCredential type='text' placeholder='CURP' value={curp} onChange={(e => handleCurp(e.target.value))}/>
