import React, { useEffect, useState } from 'react'
import { apiSendPost, baseURL, cover, getProfile, updateCover } from '../../../utils/constants'
import { ProfilePicture } from '../../microcomponentes/images/ProfilePicture'
import { CredentialsId, createCookie, fetchPost, getCookie, handleClick } from '../../../utils/functions'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'

export const ProfileContainer = () => {
  const navigate = useNavigate();
  const user = getCookie('profile');
  const userId = CredentialsId();
  const [ name, setName ] = useState('');
  const [ imageCover, setImageCover ] = useState('');
  const [ previewImageCover, setPreviewImageCover ] = useState('');
  const [ description, setDescription ] = useState('');
  const [ myProfile, setMyProfile ] = useState(false);
  const [ coverChanged, setCoverChanged ] = useState(false);
  const [ idCover, setIdCover ] = useState('');

  //crear la consulta de los datos de perfil
  async function getProfileData() {
    const response = await axios.get(getProfile + user);
    setName(response.data.name + ' ' + response.data.paternal_surename + ' ' + response.data.maternal_surename);
    setImageCover(response.data.cover);
    if(response.data.cover){setPreviewImageCover(baseURL + response.data.cover);}
    setDescription(response.data.description);
    setIdCover(response.data.id_cover);
  }

  const changeCover = (event) => {
    const file = event.target.files[0];
    setImageCover(file);  
    const prev = imagePreview(event);
    setPreviewImageCover(prev);
    setCoverChanged(true);  
  };

  const imagePreview = (event) => {
    const file = event.target.files[0];
    if (file) {
        const img = URL.createObjectURL(file);
        return img
    }
  };

async function sendCover() {
  const formData = new FormData();
  formData.append('file', imageCover);
  formData.append('text', 'Nueva foto de portada');
  formData.append('id_user', userId);
  formData.append('notification', false);
  formData.append('cover', true);

  const { data } = await fetchPost({
    url: apiSendPost,
    params: formData,
    headers: {'Content-Type': 'multipart/form-data'},
  });

  setCoverChanged(false);
  getProfileData();
}

function openPost(post, write=false){
  createCookie({
    name: 'post_opened',
    data: post
  })

  createCookie({
    name: 'write',
    data: write
  })
  navigate('/post')
}
  
  useEffect(() => {
      if(user == userId){setMyProfile(true)}
      getProfileData();
  }, [user, userId]);

  return (
    <div className='container' id='container-profile'>
        <div>
            <div className='images'>
                  <img className='cover' src={previewImageCover ? previewImageCover : cover} onClick={idCover ? (e) => openPost(idCover) : undefined}/>
                  <ProfilePicture user={user} profile={false} myProfile={myProfile}/>
                  <h2 className='name'>{name}</h2>

                  {myProfile && !coverChanged ? <button className='btn-cover' onClick={(e) => handleClick('input-change-cover')}>Cambiar portada</button> : ''}
                  {myProfile && coverChanged ? <button className='btn-cover' onClick={(e) => sendCover()}>Guardar cambios</button> : ''}

                  <input id='input-change-cover' type='file' onChange={changeCover} style={{ display: 'none' }}/>
            </div>

            <div className='comment'>{description}</div>
        </div>
    </div>
  )
}
