import axios from "axios";
import { apiGetProfilePicture, apiLoadProfilePicture } from "../constants";
import { fetchPost } from "./useFetech";

export async function getImageProfile(user) {
    const response = await axios.get(apiGetProfilePicture + user);
    return(response.data.image);
}

export async function sendImage(image, user) {
    const formData = new FormData();
    formData.append('image', image);
    formData.append('id_user', user);

    const { data } = await fetchPost({
      url: apiLoadProfilePicture,
      params: formData,
      headers: {'Content-Type': 'multipart/form-data',},
    });
}

export const prepareProfileImage = (e, preview) => {
    const selectedFile = e.target.files[0];
    let file = null;

    if (selectedFile && selectedFile instanceof Blob) {
        file = selectedFile;

        const reader = new FileReader();
        reader.onload = () => {
            const url = reader.result;
            const imgPreview = document.getElementById(preview);
            if (imgPreview) {
                imgPreview.src = url;
            }
        };
        reader.readAsDataURL(selectedFile);
    }

    return file;
};