import React, { useEffect, useState } from 'react'
import { CredentialsId, handleClick } from '../../../utils/functions'
import { baseURL, profile } from '../../../utils/constants';
import { getImageProfile, prepareProfileImage, sendImage } from '../../../utils/functions/imageProfile';
import { closeSession } from '../../../utils/functions/session';
import { useNavigate } from 'react-router-dom';

export const SettingsContainer = () => {
    const navigate = useNavigate();

    const userId = CredentialsId();
    const [profileImage, setProfileImage] = useState(null);
    const [imagePreparade, setImagePreparade] = useState(false);


    async function fetchData() {
        const image = await getImageProfile(userId);
        setProfileImage(image);
    }

    function prepareImage(e, preview){
        setProfileImage(prepareProfileImage(e, preview));
        setImagePreparade(true);
    }

    function calncelSendImage(){
        navigate('/');
    }

    useEffect(() => {
        fetchData();
    }, [userId]);

    
    return (
      <div className='container' id='container-settings'>
            <div>
                <div className='container-settings-details'>
                    <details name='settings'>
                        <summary>Cuenta</summary>
                        <div onClick={() => closeSession(navigate)}><p>Cerrar sesión</p></div>
                    </details>

                    <details name='settings'>
                        <summary>Reportes</summary>
                        <div><p>Comentarios y sugerencias</p></div>
                        <div><p>reportar un bug</p></div>
                    </details>
                </div>
            </div>
      </div>
    )
}