import React, { useEffect } from 'react';
import { createCookie, fetchPost } from '../../../utils/functions/index';
import { useCredentialsUser } from '../../../utils/hooks/useCredentialsUser';
import { InputCredential } from '../../microcomponentes/inputs/InputCredential';
import { Legend } from '../../microcomponentes/texts/Legend';
import { SubmitForm } from '../../microcomponentes/buttons/SubmitForm';
import { TextNavigate } from '../../microcomponentes/texts/TextNavigate';
import { TitleForm } from '../../microcomponentes/titles/TitleForm';
import { apiLogin } from '../../../utils/constants';
import { useNavigate } from 'react-router-dom';
import { LogoForm } from '../../microcomponentes/images/LogoForm';
import { logoApp } from '../../../utils/constants/assets';

export const FormLogin = () => {
    //Download App ----------------------------------------------------------
    const [isReadyForInstall, setIsReadyForInstall] = React.useState(false);

    useEffect(() => {
      window.addEventListener("beforeinstallprompt", (event) => {
        // Prevent the mini-infobar from appearing on mobile.
        event.preventDefault();
        //console.log("👍", "beforeinstallprompt", event);
        // Stash the event so it can be triggered later.
        window.deferredPrompt = event;
        // Remove the 'hidden' class from the install button container.
        setIsReadyForInstall(true);
      });
    }, []);




    async function downloadApp() {
        //console.log("👍", "butInstall-clicked");
        const promptEvent = window.deferredPrompt;
        if (!promptEvent) {
          // The deferred prompt isn't available.
          //console.log("oops, no prompt event guardado en window");
          return;
        }
        // Show the install prompt.
        promptEvent.prompt();
        // Log the result
        const result = await promptEvent.userChoice;
        //console.log("👍", "userChoice", result);
        // Reset the deferred prompt variable, since
        // prompt() can only be called once.
        window.deferredPrompt = null;
        // Hide the install button.
        setIsReadyForInstall(false);
    }
    /////////////////////////////////////////////////////////////////////////


    const navigate = useNavigate();

    const { employNumber, handleEmployNumber } = useCredentialsUser();
    const { password, handlePassword } = useCredentialsUser();
    const { legend, handleLegend } = useCredentialsUser();

    const handleFormSubmit = (e) => {
        e.preventDefault();
        postData()

        async function postData(){
            const { data } = await fetchPost({
                url: apiLogin,
                params: {
                    "employNumber": employNumber,
                    "password": password
                },
                headers:{'Content-Type': 'multipart/form-data'}
            })
            handleLegend(data.message);

            createCookie({
                name: 'accessToken',
                data: data.data.accessToken
            })

            if(data.code == 202){
                navigate('/')
            }
        }
    }
    

    return (
        <div className='card' id='card-login'>
            <form>
                <TitleForm colspan={15} title='Login'/>
                <LogoForm colspan={1} image={logoApp}/>
                <InputCredential type='text' placeholder='Número de empleado' value={employNumber} onChange={(e => handleEmployNumber(e.target.value))}/>
                <InputCredential type='password' placeholder='Contraseña' value={password} onChange={(e => handlePassword(e.target.value))}/>
                <Legend text={legend}/>
                <SubmitForm onClick={handleFormSubmit}/>
                <TextNavigate text='¿Aún no tienes cuenta?' link='Regístrate aquí' navigate='/register'/>
            </form>

            {isReadyForInstall && <button onClick={downloadApp}>Descargar App</button>}
        </div>
    )
}