import React, { useEffect, useRef, useState } from 'react'
import { CredentialsId, getCookie } from '../../../utils/functions'
import axios from 'axios';
import { apiGetPost, apigetCommentsPost, baseURL, imageExtensions, videoExtensions, otherExtensions, logoApp } from '../../../utils/constants';
import { getReactionImage } from '../../../utils/functions/';
import { CardUsersReactions, handleDisplayUsersReactions } from '../cards/CardUsersReactions';
import { CreateCommentPost } from './CreateCommentPost';
import { CommentsPost } from './CommentsPost';
import { ProfilePicture } from '../../microcomponentes/images/ProfilePicture';

export const PostOpened = () => {
    const post = getCookie('post_opened');
    const userId = CredentialsId();
    const write = getCookie('write');

    const [file, setFile] = useState('');
    const [text, setText] = useState([]);
    const [reactions, setReactions] = useState([]);
    const [users, setUsers] = useState([]);
    const [comments, setComments] = useState([]);
    const [name, setName] = useState('');
    const [profilePicture, setProfilePicture] = useState('');
    const [idUserPost, setIdUserPost] = useState('');

    async function getComments(post) {
        const response = await axios.get(apigetCommentsPost + post);
        setComments(response.data);
    }

    useEffect(() => {
        const getPost = async () => {
            try {
                const response = await axios.get(apiGetPost + post);
                setProfilePicture(response.data.data.image)
                let fullName = response.data.data.name + ' ' + response.data.data.paternal_surename + ' ' + response.data.data.maternal_surename;
                setName(fullName);
                
                setFile(response.data.data.file);
                setUsers(response.data.users);
                setText(response.data.data.text);
                setReactions(response.data.reactions);
                setIdUserPost(response.data.data.id_user)
                await getComments(post);
            } catch (error) {
                console.error(error);
            }
        };
        getPost();
    }, [post]);

    const updateComments = async () => {
        await getComments(post);
    };




        //Get route extension *********************************************

        
        const getFileExtension = (filename) => {
            if(filename){
                return filename.split('.').pop().toLowerCase();
            }
        };
        
        const fileExtension = getFileExtension(file);
        
        const isImage = imageExtensions.some(ext => ext === fileExtension);
        const isVideo = videoExtensions.some(ext => ext === fileExtension);
        const isOther = otherExtensions.some(ext => ext === fileExtension);
        //*****************************************************************

    return (
      <div className='container' id='container-post'>

        <div className='info-user'>
            <div>
                <ProfilePicture user={idUserPost}/>
                <div><p>{name}</p></div>
            </div>
        </div>

        <div>
            {file && (
                <div className='container-post-file'>
                    
                    {file && isImage && <div className='post-media'><img src={baseURL + file} alt="File" /></div>}
                    {file && isVideo && <div className='post-media'><video controls autoPlay><source src={baseURL + file} type={`video/${fileExtension}`} /></video></div>}
                    {file && !isImage && !isVideo && !isOther && <div className='post-media'><iframe src={file} frameborder="0"></iframe></div>}
                </div>
            )}

            {text && (
                <div className='container-post-text'>
                    {text}
                </div>
            )}

            <div className='container-post-reactions'>
              {reactions && reactions.map((reaction, index) => (
                <div key={reaction.id} onClick={() => handleDisplayUsersReactions(true)}><img src={getReactionImage(reaction.name)}/></div>            
              ))}
            </div>

            <CreateCommentPost user={userId} post={post} updateComments={updateComments} active={write} />
            <CommentsPost comments={comments}/>
        </div>

        <CardUsersReactions reactions={reactions} users={users}/>
      </div>
    )
}
