import React from 'react'
import { Navbar } from '../components/navbars/Navbar'
import { Posts } from '../components/containers/Posts'
import { Credentials } from '../../utils/functions';
import { CreatePost } from '../components/containers/CreatePost';
import { NavbarHeader } from '../components/navbars/NavbarHeader';

export const Dashboard = () => {
  const userRole = Credentials(1);

  return (
    <div>
      <Navbar/>
      <NavbarHeader/>


      {userRole >= 2 && (<><CreatePost/></>)}
      <Posts/>
    </div>
  )
}
