import React, { useState, useEffect, useRef } from 'react';
import { apiCommentPost, send } from '../../../utils/constants';
import axios from 'axios';
import { deleteCookie } from '../../../utils/functions';

export const CreateCommentPost = ({ user, post, updateComments, active = false }) => {
  const [comment, setComment] = useState('');
  const textAreaRef = useRef(null);

  useEffect(() => {
    if (active === 'true') {
      textAreaRef.current.focus();
      deleteCookie('write')
    }
  }, [active]);

  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };

  async function handleSubmit() {
    let params = {
      id_user: user,
      id_post: post,
      comment: comment
    };
    await axios.post(apiCommentPost, params);
    setComment('');
    updateComments();
  };

  return (
    <div className='container' id='container-create-comment-post'>
      <div>
        <textarea
          ref={textAreaRef}
          id='post-write'
          value={comment}
          placeholder='Comentar'
          onChange={handleCommentChange}
        />
        <img
          onClick={handleSubmit}
          src={send}
          style={{ cursor: 'pointer' }}
        />
      </div>
    </div>
  );
};
