import React from 'react'

export const TitleForm = ({ title='Title', size=1, colspan = 16 }) => {
    const HeaderTag = `h${size}`;

    return (
        <div className={`colspan-${colspan} title-form`}>
            <HeaderTag>{title}</HeaderTag>
        </div>
    );
};
