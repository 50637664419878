import React, { useState, useEffect } from 'react';
import { apiGetProfilePicture, apiSendPost, baseURL, cover, profile } from '../../../utils/constants';
import axios from 'axios';
import { createCookie, fetchPost, handleClick } from '../../../utils/functions';
import { useNavigate } from 'react-router-dom';
import { prepareProfileImage, sendImage } from '../../../utils/functions/imageProfile';

export const ProfilePicture = ({user, prof=true, myProfile=false}) => {
    const navigate = useNavigate();
    const [showImage, setShowImage] = useState(false);
    const [ id, setId ] = useState('');
    const [ image, setImage ] = useState('');
    const [ imageCover, setImageCover ] = useState('');
    const [ imageChanged, setImageChanged ] = useState(false);

    async function getPicture() {
        const response = await axios.get(apiGetProfilePicture + user);
        setImageCover(response.data.cover);
        setId(response.data.id);
        if(response.data.profile){
            setImage(response.data.profile);
        }
    }

    function goProfile(){
        createCookie({
            name: 'profile',
            data: user
        })
        navigate('/profile')
    }

    useEffect(() => {
        if(user){
            getPicture();
        }
    }, [user]);

    useEffect(() => {
        if (showImage) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = '';
        }

        return () => {document.body.style.overflow = '';};

    }, [showImage]);

    function toggleShowImage() {
        setShowImage(!showImage);
        getPicture();
    }

    function prepareImage(e, preview){
        setImage(prepareProfileImage(e, preview));
        setImageChanged(true);
    }

    async function send() {
        const formData = new FormData();
        formData.append('file', image);
        formData.append('text', 'Nueva foto de perfil');
        formData.append('id_user', user);
        formData.append('notification', false);
        formData.append('profile', true);
      
        const { data } = await fetchPost({
          url: apiSendPost,
          params: formData,
          headers: {'Content-Type': 'multipart/form-data'},
        });

        setImageChanged(false);
      }


    return (
        <>
            <div className={`image-profile ${showImage ? 'show-image-profile' : ''}`} onClick={toggleShowImage}>
                <img className='bg' src={imageCover ? baseURL + imageCover : cover}/>
                <img id="previewImageProfile" className='img' src={image ? baseURL + image : profile}/>

                {prof ? <button onClick={() => goProfile()}>Perfil</button> : ''}
                {myProfile && !imageChanged ? <button onClick={() => handleClick('profilePictureee')}>Cambiar foto</button> : ''}
                {myProfile && imageChanged ? <button onClick={() => send()}>Guardar</button> : ''}

                <input type='file' id='profilePictureee' onChange={(e) => prepareImage(e, 'previewImageProfile')} style={{ display: 'none' }}/>
            </div>


        </>
    );
}