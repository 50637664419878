import React from 'react'
import { FormLogin } from '../components/forms/FormLogin'
import { AutoLogin } from '../../utils/functions'

export const Login = () => {
  AutoLogin();

  return (
    <div>
      <FormLogin/>
    </div>
  )
}
