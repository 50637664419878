import React from 'react'
import { Navbar } from '../components/navbars/Navbar'
import { NavbarHeader } from '../components/navbars/NavbarHeader'
import { ProfileContainer } from '../components/containers/ProfileContainer'

export const Profile = () => {
  return (
    <div>
        <Navbar/>
        <NavbarHeader/>
        
        <ProfileContainer/>
    </div>
  )
}
